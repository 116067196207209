import React from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'
import Link from 'next/link'

const navRoutes = [
  { id: 1, name: 'Home', href: '/' },
  { id: 2, name: 'Book your trip', href: '/booking' },
  { id: 3, name: 'Terminals', href: '/terminal' },
  { id: 4, name: 'Services', href: '/services' },
  { id: 5, name: 'Discover ANET', href: '/about' },
  // { id: 6, name: 'News & Updates', href: '/news' },
  { id: 7, name: 'Contact us', href: '/contact' }
]

export default function Hero({ children }) {
   const [isOpen, setIsOpen] = useState(false)
   const router = useRouter()

   const genericHamburgerLine = `h-0.5 w-full my-1 rounded-full bg-white transition ease transform duration-300`
    
  return (
    <section className='hero'>
      <section className='lg:px-20 2xl:px-40 md:px-12 px-6 py-4 flex items-center justify-between border-b border-solid border-secondary w-full bg-backdrop sm:backdrop-blur-xl md:bg-transparent'>
        <Link href='/'>
          <a>
            <Image
              src='/images/ANET Logo.svg'
              alt='ANET Co.'
              className='w-full h-full'
              height={40}
              width={110}
              priority
            />
          </a>
        </Link>
        <button
          aria-label='hamburger menu'
          className='flex flex-col h-7 w-7 justify-center items-center group md:hidden'
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? 'rotate-45 translate-y-3 opacity-50 group-hover:opacity-100'
                : 'opacity-50 group-hover:opacity-100'
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isOpen ? 'opacity-0' : 'opacity-50 group-hover:opacity-100'
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? '-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100'
                : 'opacity-50 group-hover:opacity-100'
            }`}
          />
        </button>
        <section className='hidden md:flex'>
          <a
            href='tel:+2349065383758'
            className='border-b border-solid font-regular leading-none text-white md:text-sm lg:text-lg border-white transition duration-500 ease-in-out hover:text-priColor z-10 hidden md:block mr-5'
          >
            (+234) 906 538 3758
          </a>
          <a
            href='mailto:wecare@anettransport.com'
            className='border-b border-solid font-regular leading-none text-white md:text-sm lg:text-lg border-white transition duration-500 ease-in-out hover:text-priColor z-10 hidden md:block'
          >
            wecare@anettransport.com
          </a>
        </section>
      </section>
      <nav
        className={`md:block my-2 md:my-3 absolute md:relative z-20 w-full h-4/5 py-16 md:h-14 md:py-0 bg-navBg md:bg-navMain md:backdrop-blur-xl transition-all duration-1000 ease-linear ${
          isOpen ? ' active' : ' notActive'
        }`}
      >
        <ul className='flex px-6 md:px-0 md:items-center justify-between md:flex-row md:py-4 md:w-4/5 lg:w-9/12 mx-auto md:px-8 flex-col h-4/5 md:h-full items-start transition-all duration-700 ease-linear delay-100'>
          {navRoutes.map((route) => (
            <li key={route.id} onClick={() => setIsOpen(!isOpen)}>
              <Link href={route.href}>
                <a
                  className={
                    (router.pathname === route.href ? 'active ' : '') +
                    'text-xl md:text-base lg:text-lg text-cardGrey md:text-white transition duration-500 ease-in-out hover:text-priColor z-10 flex items-center justify-center font-bold md:font-normal'
                  }
                >
                  {route.name}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <section className='pb-10'>{children}</section>
    </section>
  )
}
